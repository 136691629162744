.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3 {
  margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.portfolio__container img {
  float: left;
  width:  300px;
  height: 300px;
  object-fit: cover;
}

.btn-details {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  margin-right: 1rem; /* pour l'espacement entre le bouton Git et le bouton Détails */
}

.btn-details:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.close {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  filter: invert(1);
  max-width: 32px;
  max-height: 32px;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.skills {
  margin-top: 2rem;
  margin-right: 2rem;
}

.skill-item {
  font-weight: bold;
  margin-left: 1em;
}


/* ------------------- MEDIA QUERIES (Medium) ---------------------*/
@media screen and (max-width: 1024px){
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
    .videos video{
      max-width: 90%;
    }
}
 
 
 /* ------------------- MEDIA QUERIES (Small) ---------------------*/
 @media screen and (max-width: 600px){
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
   
}