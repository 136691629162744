header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}


/*------------------CTA--------------------*/

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
    padding: 1rem;
}


/*-----------------HEADER SOCIALS-------------------*/
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 0.3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/*-----------------ME-------------------*/
.me {
    width: 28%;
    position: relative;
    margin: 0 auto;
    display: block;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

/*-----------------SCROLL DOWN-------------------*/
.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;
}

/* ------------------- MEDIA QUERIES (Medium) ---------------------*/
@media screen and (max-width: 1024px){
    header {
        height: 68vh;
    }
}


/* ------------------- MEDIA QUERIES (Small) ---------------------*/
@media screen and (max-width: 600px){
    .header__socials,
    .scroll__down{
        display: none;
    }
}